#remote-modal .modal-header{
  padding: 0.5rem 1rem;
}

@include media-breakpoint-down(md){

  #remote-modal .modal-content{
      border-radius: 15px;
      border-top-right-radius: 18px;
      border-top-left-radius: 18px;
  }

  #remote-modal .modal-header{
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
  }

}
