.main-content.market .accordion-body {
  padding: 5px 1.25rem;
}

@include media-breakpoint-up(sm) {
 .main-content.market .accordion-body {
      padding: 5px 1.25rem;
  }
}

#main-section .main-content.market .accordion-item-subcategory{
  margin-bottom: 5px;
}

.main-content.market .product-name{
  line-height: 22px;
}

.main-content.market .product-name a{
  text-decoration: none;
  color: $black;
}

#main-section .main-content.market ul li{
  background: none;
  padding: 10px;
  list-style: none;
}

.main-content.market .accordion-button:focus{
  border: none;
}

.main-content.market .accordion-button:focus{
  box-shadow: none;
}

.main-content.market .accordion-button{
  font-size: 13.5pt;
}

.main-content.market .accordion-flush .accordion-item:first-of-type .accordion-button, 
.main-content.market .accordion-flush .accordion-button{
  border-radius: 10px;
}

.accordion-flush .accordion-item:last-of-type .accordion-button.collapsed{
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}


.main-content.market .accordion-button:not(.collapsed), 
.main-content.market .accordion-button:hover{
  color: $black;
  background-color: $deraices;
}


.main-content.market .accordion-button-subcategory:not(.collapsed),
.main-content.market .accordion-button-subcategory:hover{
  background-color: #839839;
  color: $white;
}

.main-content.market div.product{
  font-size: 15pt;
  padding-bottom: 15px;
}


.main-content.market div.product > div{
  height: 100%;
}

.main-content.market div.product img{
  width: 100px;
  height: 100px;
  border-radius: 10px;
}

.main-content.market div.product .price{
  font-family: "roboto-condensedbold";
  font-size: 15pt;
}

#main-section .main-content.market .btn-view-current-order .icon{
  width: 16px;
}

#main-section .main-content.market .accordion-button::after{
  background-position-y: 8px;
}

#main-section .main-content.market .accordion-button::after,
#main-section .main-content.market .accordion-button:not(.collapsed)::after,
#main-section .main-content.market .accordion-button-subcategory::after{
  background-image: url('/images/icons/arrow-b.png');
}

#main-section .main-content.market .accordion-button-subcategory:hover::after{
  background-image: url('/images/icons/arrow-w.png');
}

#main-section .main-content.market .accordion-button-subcategory:not(.collapsed)::after{
  background-image: url('/images/icons/arrow-w.png');
}

.modal-dialog.product-show img{
  width: 300px;
  height: 300px;
}


@include media-breakpoint-down(md){

  .main-content.market .accordion-body {
    padding: 5px 0.5rem;
  }

  .main-content.market div.product {
    font-size: 13pt;
  }

  .main-content.market div.product img{
    width: 80px;
    height: 80px;
  }

  .main-content.market div.product .price .cart-controls{
    margin-left: 15px;
  }

  .main-content.market .product-name{
    line-height: 19px;
  }

  .modal-dialog.product-show .badge{
    font-size: 0.9em;
  }

  .modal-dialog.product-show img{
    width: 100%;
    height: auto;
  }

  .main-content.market .accordion-button{
    padding-bottom: 12px;
    padding-top: 12px;
  }

}
