
#main-section .main-content.current-order .checkout-table tfoot,
#main-section .main-content.current-order .checkout-table thead{
  font-family: "roboto-condensedbold";
}

#main-section .main-content.current-order .checkout-table thead th{
  background-color: $deraices;
  border-bottom: none;
}

#main-section .main-content.current-order .checkout-table tfoot{
  border-color: transparent;
}

#main-section .main-content.current-order .checkout-table td{
  border: 1px solid $deraices;
}

#main-section .main-content.current-order .checkout-table thead th:last-child,
#main-section .main-content.current-order .checkout-table tr td:last-child
{
  background-color: transparent;
  border: none;
}

#main-section .main-content.current-order .checkout-table > :not(caption) > * {
  border-width: 0px 0px 0px 1px;
}



#main-section .main-content.current-order .btn-subtitle{
  width: 170px;
}

#main-section .main-content.current-order .checkout-table td{
  line-height: 1;
  font-size: 12pt;
  vertical-align: middle;
}

#main-section .main-content.current-order .checkout-table .cart-controls-container{
  height: 50px;
}

#main-section .main-content.current-order .checkout-table img.product-image{
  width: 50px;
  height: 50px;
  border-radius: 10px;
  margin-left: 4px;
}

#main-section .main-content.current-order .checkout-table img.icon{
  width: 15px;
  cursor: pointer;
}

.main-content.current-order .btn-group-delivery-type label.btn{
  border-color: $deraices;
}

#main-section .main-content.current-order .donations img{
  width: 90%;
}

#main-section .main-content.current-order .totals-table{
  font-family: "roboto-condensedbold";
  font-size: 16px;
}

#main-section .main-content.current-order table .cart-controls-item-remove button{
  background: none;
  border: none;
}


@include media-breakpoint-down(md){
  #main-section .main-content.current-order .cart-controls .input-group{
    width: 45px;
  }

  #main-section .main-content.current-order .cart-controls .quantity-selector{
    padding: 0;
    width: 45px;
    border: none;
  }

  #main-section .main-content.current-order .btn-group-delivery-type{
    width: 100%;
  }

  #main-section .main-content.current-order .donations img{
    width: 100%;
  }

  #main-section .main-content.current-order .checkout-table thead{
    font-size: 12pt;
    line-height: 14px;
  }

  #main-section .main-content.current-order .checkout-table .mobile-no-padding{
    padding-left: 3px;
    padding-right: 3px;
  }

  #main-section .main-content.current-order .checkout-table td.cart-controls-td{
    vertical-align: bottom;
    padding-bottom: 2px;
  }

  #main-section .main-content.current-order .cart-controls .btn-group-quantity .btn{
    height: 22px;
    padding: 0.0rem 0.35rem;
  }

}
