@import "~bootstrap/scss/functions";

// Typography
$font-family-sans-serif: 'roboto-condensed', sans-serif;
$body-color: #121212 !default;

$font-size-body: 13pt;
$font-size-title: 23px;

// Colors
$green: #397338 !default;
$green-light: #839839 !default;
$green-strong: #5b752a !default;
$black: #272727 !default;
$deraices: #b8e95b;
$deraices-active: #95b71e;
$btn-deraices: #b8e95b;
$btn-deraices-active: #a2cc50;
$yellow: #b4de23;
$hr-light: #cdd5db;

//toast
$toast-max-width: 500px;

//accordion
$accordion-button-active-bg: tint-color($green, 90%);
$accordion-button-active-color: shade-color($green, 10%);
$accordion-button-focus-border-color: $green;
$accordion-button-focus-box-shadow: 0 0 0 .25rem $green;


