.my-profile .my-addresses .address-attribute .fa{
  color: #808080;
  margin-right: 10px;
}

.my-profile .my-addresses .address-attribute .icon{
  width: 14px;
  margin-right: 8px;
}

.my-profile .menu .btn{
  border-bottom: 1px solid #cdd5db;
}

#main-section .my-profile h2{
  text-transform: capitalize;
}

#main-section .my-addresses .card{
  border-radius: 10px;
}

#main-section .my-addresses .card-header{
  text-align: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 0.1rem 1rem;
  font-size: 15pt;
}

#main-section .my-addresses .card-header{
  background-color: $white;
  color: $black;
}

#main-section .my-addresses .card-header.main{
  background-color: #008850;
  color: $white;
}

#main-section .my-addresses .card-header.make-main:hover{
  background-color: #008850;
  color: $white;
}

#main-section .my-addresses .card-buttons .btn{
  padding: 0.1rem 0.75rem;
}

@include media-breakpoint-down(md){
  .my-profile .menu{
    width: 100%;
  }

  #main-section .my-profile .menu .btn{
    font-size: 15pt;
  }

  // .my-profile .menu .btn, 
  // .my-profile .menu .btn.active,
  // .my-profile .menu .btn:focus,
  // .my-profile .menu .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle), 
  // .my-profile .menu .btn-group-vertical > .btn-group:not(:last-child) > .btn{
  //   border-radius: 0.25rem;
  //   border-bottom-right-radius: 0.25rem !important;
  //   border-bottom-left-radius: 0.25rem !important;
  // }

}
