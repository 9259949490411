// Variables
@import 'variables';
@import 'fonts';

// Vendor
@import '~bootstrap/scss/bootstrap';
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/regular";


//pages
@import "header";
@import "contacts";
@import "footer";
@import "home";
@import "news";
@import "services";
@import "delivery_zones";
@import "forms";
@import "my_profile";
@import "market";
@import "current_order";
@import "cart_controls";
@import "remote_modal";
@import "buttons";

.site-title{
  font-size: 40pt;
  font-family: 'sansita-medium';
}

.site-title span{
  font-size: 32.5pt;
  font-family: 'roboto-condensedlight';
  letter-spacing: -1px;
}

#main-section{
  font-size: $font-size-body;
}

#main-section h2{
  font-size: $font-size-title;
  text-transform: uppercase;
}

#main-section h2.subtitle{
  border-bottom: solid 2px #4e4e4e;
  padding-bottom: 20px;
  margin-bottom: 40px;
}

#main-section h2.subtitle.no-border{
  border: none;
  padding-bottom: 0;
  margin-bottom: 20px;
}

#main-section h3{
  font-size: 16.5pt;
  margin-bottom: 1.5rem;
}

#main-section p{
  margin-bottom: 1.5rem;
}

#main-section ul li{
  background: url('/images/check.png') no-repeat 0 18px;
  background-size: 30px;
  padding: 19px 0px 8px 56px;
  /* reset styles (optional): */
  list-style: none;
  margin: 0;
}

#main-section ul.no-style li{
  background: none;
  padding: 0;
  list-style: unset;
}

#main-section ol li:before {
  counter-increment: myOrderedListItemsCounter;
  content: counter(myOrderedListItemsCounter)" ";
  margin-right: 0.5em;
  background: url('/images/ol-bg.png') no-repeat 0 12px;
  padding: 19px 10px 8px 16px;
  color: $white;
}

#main-section .main-content{
  padding-top: 30px;
}

#main-section #search input{
  max-width: 711px;
  font-size: 11pt;
}

#main-section #search .btn-search{
  background-color: #c8d4a3ff;
  color: #67645aff;
}

img.force-square {
  object-fit: cover;
}

.badge{
  background-color: $green;
}

.bg-deraices{
  background-color: $deraices;
}

.bg-green-light{
  background-color: $green-light;
}

.bg-yellow{
  background-color: $yellow;
}

.text-darky{
  color: $body-color
}

hr.light{
  color: $hr-light;
}

.hero-image.container-fluid{
  padding: 0;
  text-align: center;
  position: relative;
}

.hero-image.container-fluid img.img-fluid{
  width: 100%;
}

.hero-image.container-fluid .hero-shadow{
  position: absolute;
  width: 100%;
  height: 100%;
  box-shadow: inset rgba(0, 0, 0, 0.35) 0px 5px 15px;
  top: 0;
  left: 0;
}

.whatsapp-fixed{
  position: fixed;
  bottom: 23px;
  right: 2rem;
  z-index: 1000;
  cursor: pointer;
}

.whatsapp-fixed img{
  width: 75px;
}

/* hide input number arrows */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}



/* Toast Popper*/

.toast .toast-body{
  font-size: 20px;
}

@include media-breakpoint-down(md){
  
  #main-section{
    font-size: 15pt;
  }

  #main-section h2{
    font-size: 15pt;
    text-transform: uppercase;
  }

  #main-section h2.subtitle, #main-section .subtitle.h2{
    padding-bottom: 10px;
    margin-bottom: 15px;
    border-bottom: solid 1px #4e4e4e;
  }

  #main-section ul{
    padding: 0;
  }
  
  #main-section ul li{
    padding: 17px 0px 8px 40px;
  }

  #main-section .main-content {
    padding-top: 10px;
  }

  .hero-image.container-fluid img.img-fluid{
    height: 110px;
    object-fit: cover;
    width: auto;
  }

  .hero-image.container-fluid img.adjust-position{
    object-position: -200px 0px;
  }

  .modal-backdrop
  {
      opacity:0 !important;
  }

  .modal-backdrop-show
  {
      opacity:0.5 !important;
  }

  .whatsapp-fixed{
    bottom: 8px;
    right: 8px;
  }

  .whatsapp-fixed img{
    width: 55px;
  }

  #main-section h2.subtitle.no-border{
    border: none;
    padding-bottom: 0;
    margin-bottom: 10px;
  }

  .modal-title {
    line-height: 1.1;
  }

}

@media (min-width: 380px) {
  .hero-image.container-fluid img.adjust-position{
    object-position: -100px 0px;
  }
}

@media (min-width: 446px) {
  .hero-image.container-fluid img.adjust-position{
    object-position: 0px 0px;
  }
}

@media (min-width: 446px) {
  .hero-image.container-fluid img.img-fluid{
    width: 100%;
    height: auto;
  }
}


@media (min-width: 768px) and (max-width: 830px) {
  .site-title{
    font-size: 37pt;
  }
  .site-title span{
    font-size: 27pt;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .modal-dialog {
    max-width: 760px;
  }
}
