.btn{
  font-size: 13pt;
}

.btn-deraices{
  background-color: $btn-deraices;
  color: $black;
}

.btn-green-strong{
  background-color: $green-strong;
  color: $white;
}

.btn-green-strong .fa{
  color: $white;
}

.btn-green-strong:hover{
  color: $white;
  background-color: #42561c;
}

.btn-deraices.active, .btn-deraices:hover{
  background-color: $btn-deraices-active;
  color: $black;
}

.btn-outline-deraices {
  color: $black;
}

.btn-outline-deraices:hover, .btn-check:checked + .btn-outline-deraices, 
.btn-check:active + .btn-outline-deraices, .btn-outline-deraices:active, 
.btn-outline-deraices.active, .btn-outline-deraices.dropdown-toggle.show{
  color: $black;
  background-color: $btn-deraices;
  border-color: $btn-deraices;
}

.btn-outline-deraices.active {
  color: $black;
  background-color: $btn-deraices;
  border-color: $btn-deraices;
}

.btn-rounded{
  border-radius: 35px;
}

#main-section .btn-subtitle{
  font-size: 12pt;
  background-color: #008850;
  color: #fff;
  width: 130px;
  text-transform: capitalize;
}

#main-section .btn-subtitle .icon{
  width: 15px;
}
