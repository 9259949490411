#main-section .main-content.services h3.services-title{
  font-size: 20px;
}

.main-content.services .services-body .fa{
  color: #808080
}

.main-content.services .services-body img{
  border-radius: 10px;
}


@include media-breakpoint-down(md){
  
  #main-section .main-content.services h3{
    margin-bottom: 1rem;
  }

  #main-section .main-content.services h3.services-title{
    font-family: "roboto-condensedbold";
  }

  #main-section .main-content.services h3.services-title,
  #main-section .main-content.services .services-body,
  #main-section{
    font-size: 15pt;
  }  

  .main-content.services .services-body{
    overflow-wrap: break-word;
  }

}


@include media-breakpoint-down(md){
  .main-content.services .services-body img{
    width: 33%;
    padding-left: 10px; 
    padding-bottom: 10px; 
  }
}
