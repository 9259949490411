
.main-content .form label{
  // font-size: 12pt;
}

.main-content .form input.form-control{
  // height: 40px;
}

.main-content .form a:not(.btn){
  color: $black;
}

.main-content .form .input-group-text{
  // padding: 0.375rem 1.5rem;
  // font-size: 10pt;
}

.main-content .form input.is-invalid{

  border-color: #f5c2c7;
}


.main-content .form .select2-container .select2-selection--single{
  height: 40px;
}

.main-content .form .select2-container--default .select2-selection--single .select2-selection__rendered{
  line-height: 40px;
  // font-size: 12pt;
}

.main-content .form .select2-container--default .select2-selection--single .select2-selection__arrow{
  top: 9px;
}

.main-content .form .is-invalid ~ .select2 .select2-container--default .select2-selection--single  {
  border: 1px solid red !important;
}

.main-content .form .btn-deraices .fa{
  margin-right: 8px;
}
