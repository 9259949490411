
#header .logo{
  width: 85px;
}

#header img.bag{
  height: 90px;
}

#header .header-top{
  height: 120px;
}

#header h1{
  line-height: 1.25;
  margin-left: 28px;
}

#header .menu a{
  text-transform: uppercase;
  font-size: 13pt;
  text-decoration: none;
}

#header .dropdown-toggle{
  font-size: 13.5pt;
  cursor: pointer;
}

#header .dropdown-toggle #current-order-total{
  font-family: "roboto-condensedbold";
}

#header .dropdown-toggle::after{
  border: none;
  content: none;
}

#header .dropdown-menu{
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 2.95px 2.95px 2.6px;
  padding: 0;
}

#header .nav-container{
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

#header .dropdown-item:hover, .dropdown-item:focus {
  background-color: $deraices;
}

#header .dropdown-menu li:first-child .dropdown-item:hover, 
#header .dropdown-menu li:first-child .dropdown-item:focus {
  border-radius: 8px 8px 0 0;
}

#header .dropdown-menu li:last-child .dropdown-item:hover, 
#header .dropdown-menu li:last-child .dropdown-item:focus {
  border-radius: 0 0 8px 8px;
}



#header .dropdown .dropdown-divider{
  margin: 0;
}

#header .dropdown .dropdown-item{
  padding: 15px;
}

#header .guest-actions .btn{
  width: 120px;
  font-size: 11pt;
}

#header .icon{
  width: 18px;
}

#header .user-actions .icon{
  margin-right: 10px;
}

#header .user-info{
  line-height: 19px;
}

@include media-breakpoint-down(md){

  #header{
    padding-left: 10px;
    padding-right: 18px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 7px;
  }

  #header .header-top{
    height: 58px;
    padding: 0;
    padding-right: 0;
  }

  #header .logo{
    width: 40px;
  }

  #header .site-title{
    line-height: 0.75;
    margin-left: 9px;
    font-size: 18.5pt;
    margin-bottom: 0;
  }

  #header .site-title span{
    font-size: 14.3pt;
  }

  #header .btn.login{
    font-size: 11px;
    width: 83px;
    height: 32px;
    line-height: 18px;
  }

  #header .btn.login img.icon{
    width: 13px;
    height: 18px;
  }

  #header .mobile-dropdown-toggle{
    padding: 0;
    margin-left: 18px;
  }

  #header .mobile-dropdown-toggle img{
    width: 20px;
  }

  #mobile-menu .btn-group-vertical{
    width: 100%;
  }

  #mobile-menu .modal-dialog{
    margin: 1rem;
    margin-top: 60px;
  }

  #mobile-menu .modal-content{
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.15) 2.95px 2.95px 2.6px;
  }

  #mobile-menu .modal-body{
    padding: 0;
  }

  #mobile-menu .btn-group-vertical .btn{
    text-align: left;
    padding: 5px 15px 5px 15px;
    font-size: 16pt;
  }

  #mobile-menu .btn-group-vertical .btn .icon{
    width: 16px;
    margin-right: 15px;
  }

  #mobile-menu .btn-group-vertical .btn:first-child{
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
  }

  #mobile-menu .btn-group-vertical .btn-divider{
    border-bottom: 1px solid #abb1ad;
  }

  #mobile-menu .btn-group-vertical .btn-divider-top{
    border-top: 1px solid #abb1ad;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  #mobile-menu .btn-group-vertical .logout-form, #mobile-menu .btn-group-vertical .logout-form .btn{
    width: 100%;
  }

  #mobile-menu .btn-group-vertical .btn:last-child{
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
  }

  #header .user-info{
    line-height: 19px;
    font-size: 13pt;
  }

  #header img.bag{
    height: 40px;
    cursor: pointer;
  }

}
