.cart-controls .input-group{
  width: 92px;
}

.cart-controls .quantity-selector{
  text-align: center;
}

.cart-controls .btn-group-quantity .btn{
  font-size: 8pt;
  background-color: #617229;
  color: $white;
}

.cart-controls .btn-group-quantity .btn.left{
  border-radius: 16px 0 0 16px;
}

.cart-controls .btn-group-quantity .btn.right{
  border-radius: 0 16px 16px 0;
}

.cart-controls .btn-group-quantity .btn,
.cart-controls .btn-group-quantity input{
  height: 25px;
}
