@font-face {
    font-family: 'roboto-condensedlight';
    src: url('/fonts/RobotoCondensedLight/robotocondensed-light.ttf') format('ttf'),
         url('/fonts/RobotoCondensedLight/robotocondensed-light.woff2') format('woff2'),
         url('/fonts/RobotoCondensedLight/robotocondensed-light.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'roboto-condensedbold';
    src: url('/fonts/RobotoCondensedBold/robotocondensed-bold.ttf') format('ttf'),
         url('/fonts/RobotoCondensedBold/robotocondensed-bold.woff2') format('woff2'),
         url('/fonts/RobotoCondensedBold/robotocondensed-bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'roboto-condensed';
    src: url('/fonts/RobotoCondensed/robotocondensed.ttf') format('ttf'),
         url('/fonts/RobotoCondensed/robotocondensed.woff2') format('woff2'),
         url('/fonts/RobotoCondensed/robotocondensed.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'sansita-medium';
    src: url('/fonts/Sansita-Medium/Sansita-Medium.ttf') format('ttf'),
         url('/fonts/Sansita-Medium/Sansita-Medium.woff2') format('woff2'),
         url('/fonts/Sansita-Medium/Sansita-Medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


.bold, b, strong{
  font-family: "roboto-condensedbold";
}
