
.main-content.delivery-zones{
  line-height: 15pt;
}

.main-content.delivery-zones .zones{
  border-right: 2px solid #dee2e6;
}

.main-content.delivery-zones > div.row{
  margin-right: 0;
  margin-left: 0;
}

.main-content.delivery-zones .zone-box{
  width: 22px; 
  height: 22px; 
  border-radius: 5px;
}

.main-content.delivery-zones .zone-name{
  text-transform: uppercase;
}

.main-content.delivery-zones #zones-container{
  border-top: solid 2px #4e4e4e;
  border-bottom: solid 2px #dddddd;
}


.main-content.delivery-zones .map-container iframe{
  width: 100%;
  border-radius: 0 10px 10px 0;
}

.main-content.delivery-zones .zones{
  min-width: 143px;
}

.main-content.delivery-zones .hoods{
  padding-left: 20px;
}


@include media-breakpoint-down(md){

  .main-content.delivery-zones .zones{
    border-right: none;
  }

  .main-content.delivery-zones .hoods {
    padding-left: 0px;
  }

  .main-content.delivery-zones #zones-container{
    border-top: solid 1px #dddddd;
    border-bottom: solid 1px #dddddd;
  }

  .main-content.delivery-zones{
    line-height: 16pt;
  }

  #mobile-map-container{
    width: 100%;
  }

  #mobile-map-container iframe{
    width: 100%;
    height: 100%;
  }

  
  
}

