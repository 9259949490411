.main-content.news .news-date{
  font-size: 20px;
}

.main-content.news .news-title{
  font-size: 20px;
}

.main-content.news .news-main-image, .main-content.news .news-body img{
  width: 100%;
  border-radius: 10px;
}

@include media-breakpoint-down(md){

  #main-section .main-content.news .news-date,
  #main-section .main-content.news .news-title,
  #main-section .main-content.news .news-body{
    font-size: 15pt;
  }

  #main-section .main-content.news .news-title{
    font-family: 'roboto-condensedbold';
  }

  #main-section .main-content.news p{
    margin-bottom: 0;
  }



}
