.calendar .days-description{
  font-family: "roboto-condensedlight";
  font-size: 14.5pt;
}

.thanks p{
  font-size: 20.5pt;
}

.thanks .site-title{
  font-size: 17.5pt;
  margin-left: 10px;
}

.thanks .site-title span{
  font-size: 14.5pt;
}

.thanks .logo{
  width: 37.5px;
}

@include media-breakpoint-down(md){
  .calendar .days-descriptions{
    width: 75%;
  }
}
