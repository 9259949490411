#main-section .contacts .column:first-child{
  margin-right: 73pt;
}

#main-section .contacts .column:last-child{
  padding-left: 37pt;
}

#main-section .contacts .column p{
  margin-bottom: 5px;
}

#main-section .contacts .icon{
  width: 18px;
  margin-right: 10px;
}

@include media-breakpoint-down(md){
  #main-section .contacts p{
    margin-bottom: 0;
  }
}
